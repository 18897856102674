@import '@postgres.ai/shared/styles/vars';

.root {
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.flex {
    flex: 1 1 100%;
  }
}
