.container {
  display: flex;
  gap: 60px;
  margin-top: 20px;
  max-width: 1200px;

  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 20px;
  }
}

.title {
  margin-top: 16px;
}

.form {
  flex: 1 1 0;
}

.snippetContainer {
  flex: 1 1 0;
  min-width: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 0 20px 10px 20px;
  height: max-content;
  border-radius: 4px;
}

.section {
  & + .section {
    margin-top: 24px;
  }
}

.title {
  font-size: inherit;
  font-weight: 700;
}

.text {
  margin-top: 8px;
}

.marginBottom {
  margin-bottom: 10px;
}

.remark {
  font-size: 12px;
}

.snapshotTag {
  font-weight: 700;
  margin-left: 4px;
}

.summary {
  display: flex;
  align-items: center;
  background-color: rgb(250, 250, 250) !important;
  box-shadow: none !important;
  padding: 12px;
  margin: 10px 0 15px 0;
}

.summaryIcon {
  width: 30px;
  color: silver;
  margin-right: 12px;
}

.params {
  flex: 1 1 100%;
}

.param {
  display: flex;
  justify-content: space-between;
}

.controls {
  display: flex;
  align-items: center;
}

.spinner {
  margin-left: 8px;
}

.elapsedTime {
  margin-left: 24px;
}
