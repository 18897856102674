@import '@postgres.ai/shared/styles/vars';
@import '@postgres.ai/shared/styles/mixins';

.root {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: $color-gray-darkest;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  height: 48px;
  width: 100%;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  text-decoration: none;

  @include touch-transition(background-color color);

  &:hover {
    background-color: $color-gray-darkest--hover;
    color: inherit;
  }

  &.collapsed {
    justify-content: center;
  }

  + .root {
    margin-top: 8px;
  }
}

.icon {
  font-size: 0;

  &:not(.collapsed) {
    margin-right: 8px;
  }
}
