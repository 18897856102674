.root {
  flex: 0 0 100%;
  display: flex;
  min-height: 0;
}

.menu {
  flex: 0 0 auto;
}

.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
