.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 100%;
}

.form {
  width: 320px;
}

.desc {
  margin-top: 12px;
}

.field {
  margin: 24px 0 0 0 !important;
}

.button {
  margin-top: 16px !important;
}
