.tableRow {
  display: inline-table !important;
  width: 100%;
}

.tableBody {
  height: 100%;
  
  div:not(:first-child) {
    padding-top: 0.75rem;
    border-top: 1px solid rgba(224, 224, 224, 1);
  }

  div {
    padding: 0.75rem 0;
  }

  td {
    border-bottom: 0;
    padding: 0px 18px;
    font-size: 13px;
    font-family: 'Fira Code', monospace;   
  }
}

.tableSubtitle {
  font-size: 15px;
  font-weight: bold;
}