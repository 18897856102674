.root {
  display: flex;
  margin-top: 4px;
  font-size: 12px;
}

.name {
  flex: 0 0 134px;
  margin-right: 14px;

  @media screen and (max-width: '600px') {
    flex: 1 1 100%;
    margin-right: 7px;
  }
}

.content {
  flex: 1 1 100%;
  font-weight: 700;
  width: 0;
}
