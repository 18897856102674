@import './vars';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family-text;
  font-size: $font-size-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $font-family-code;
}

button {
  font-family: inherit;
  font-size: inherit;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
