.root {
  padding: 28px 24px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: '600px') {
    padding: 24px 12px 24px 12px;
  }
}
