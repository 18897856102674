.textField {
  width: 400px;
  max-width: 100%;

  input,
  label {
    font-family: 'Fira Code', monospace;
    color: #000;
  }

  label {
    font-size: 10px;
  }

  :disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.38);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.chipContainer {
  width: 350px;
  max-width: 100%;
  margin-bottom: 0;
}

.databasesContainer {
  width: 350px;
  max-width: 100%;
}

.chip {
  margin-right: 8px;
  margin-top: 8px;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.root {
  font-size: 12px;
  max-width: 450px;
}

.spinner {
  margin-left: 8px;
}

.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.infoIcon {
  margin-left: 6px;
  height: 12px;
  width: 12px;
}

.externalIcon {
  margin-left: 2px;
  height: 12px;
  width: 12px;
}

.externalLink {
  display: inline-flex;
  align-items: center;
  width: max-content;

  &:link,
  &:hover {
    color: #0000ee;
  }

  &:visited {
    color: #551a8b;
  }
}

.largeIcon {
  height: 15px;
  width: 15px;
}

.subsection {
  margin-bottom: 0.25rem;
  font-size: 13px;
  font-weight: 500;
}

.modalTitle {
  font-size: 16px;
  font-weight: bold !important;
}

.sectionTitle {
  display: flex;
  align-items: center;
  gap: 3px;
}

.firaCodeFont {
  font-family: 'Fira Code', monospace !important;
}

.snackbar {
  div {
    background-color: #ff6212;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
}
